<template lang="html">
  <div class="video-player">
    <video ref="video" @click="playpause" :src="file" playsinline controlsList="nodownload noremoteplayback" @timeupdate="onTimeUpdate" @loadedmetadata="loadVideo">
      <source :src="file" :type="'video/' + ext">
    </video>
    <div class="controls">
      <div class="playpause" @click="playpause">
        <span v-if="playing">Pause</span>
        <span v-else>Play</span>
      </div>
      <div class="time">{{ displayTime }}</div>
      <input class="range" type="range" min="0" :max="duration" step="any" value="0" v-model="time" @change="changeTime">
      <div class="muted" @click="toggleMute">
        <span v-if="muted">Unmute</span>
        <span v-else>Mute</span>
      </div>
      <div class="playpause" @click="toggleFullscreen">Fullscreen</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    file: String,
    ext: String,
  },
  data() {
    return {
      playing: false,
      muted: false,
      displayTime: "00:00",
      fullscreen: false,
      time: 0,
      duration: 0,
    }
  },
  mounted() {
    this.$root.$on('stop-playing', this.stop)
  },
  methods: {
    loadVideo() {
      this.time = 0
      this.duration = this.$refs.video.duration
      this.displayTime = this.timeParse(this.$refs.video.currentTime) + ' / ' + this.timeParse(this.$refs.video.duration)
    },
    onTimeUpdate() {
      this.time = this.$refs.video.currentTime
    },
    changeTime(event) {
      this.$refs.video.removeEventListener('timeupdate', this.timeline)
      this.$refs.video.currentTime = event.target.value
      this.$refs.video.addEventListener('timeupdate', this.timeline)
    },
    stop() {
      if (this.playing) {
        this.playing = false
        this.$refs.video.pause()
      }
    },
    toggleMute() {
      this.$refs.video.muted = !this.$refs.video.muted
      this.muted = !this.muted
    },
    pad(val) {
      val = Math.floor(val)
      if (val < 10) {
        return '0' + val
      }
      return val + ''
    },
    timeParse(sec) {
      let min = 0
      min = Math.floor(sec / 60)
      sec = sec - min * 60
      return this.pad(min) + ':' + this.pad(sec)
    },
    playpause() {
      this.playing = !this.playing
      if (!this.playing) {
        this.$refs.video.pause()
      } else {
        this.$refs.video.play()
        this.$refs.video.addEventListener('timeupdate', this.timeline)
        this.$refs.video.addEventListener('ended', () => {
          this.playing = false
        })
      }
    },
    timeline() {
      // console.log('time changing', this.$refs.video.duration, this.$refs.video.currentTime)
      this.displayTime = this.timeParse(this.$refs.video.currentTime) + ' / ' + this.timeParse(this.$refs.video.duration)
    },
    toggleFullscreen() {
      if (this.$refs.video.requestFullScreen) {
        this.$refs.video.requestFullScreen();
      } else if (this.$refs.video.webkitRequestFullScreen) {
        this.$refs.video.webkitRequestFullScreen();
      } else if (this.$refs.video.mozRequestFullScreen) {
        this.$refs.video.mozRequestFullScreen();
      } else if (this.$refs.video.webkitEnterFullScreen) {
        this.$refs.video.webkitEnterFullScreen();
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.video-player {
    position: relative;
    z-index:200;
    width: 100vw;
    height: 100vh;
    padding: calc(6 * var(--lh));
    @media only screen and (max-width:768px) {
        padding: calc(4 * var(--lh)) calc(2 * var(--lh));
    }
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;
    .controls {
        color: #000;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        margin-top: var(--lh);
        input[type="range"] {
            -webkit-appearance: none;
            border-top: 1px solid black;
            height: 0;
            margin: 0 var(--lh);
            width: 20vw;
            @media only screen and (max-width:768px) {
                width: 100%;
                margin: var(--lh) 0;
            }
            outline: none;
            cursor: pointer;
            &:focus {
                outline: 0;
            }
            &::-webkit-slider-thumb {
                -webkit-appearance: none;
                border-left: 1px solid black;
                height: var(--lh);
                width: 0;
                cursor: pointer;
            }
        }
        div {
            padding: 0 calc(0.5 * var(--lh));
            cursor: pointer;
            transition: color 0.5s;
            &:hover {
                color: #AAA;
            }
        }
    }
    video {
        max-width: 100%;
        max-height: calc(100% - (2 * var(--lh)));
        outline: none;
    }
}
</style>
